<template>
  <div class="main-body">
    <a-form ref="formRef" :model="formState" v-bind="formItemLayout" @finishFailed="onFinishFailed" @finish="onFinish">
      <a-tabs>
        <a-tab-pane key="1" tab="基础信息">
          <a-form-item label="标题" name="title" :rules="[{ required: true, message: '请输入标题!' }]">
            <a-input v-model:value="formState.title" placeholder="请输入标题" />
          </a-form-item>
          <a-form-item label="封面" name="thumb" extra="请上传280*140px的图片">
            <div>
              <a-input v-model:value="formState.thumb" placeholder="输入封面图片地址或者上传封面" class="medium-input" />
              <a-form-item-rest>
                <CsImage v-model:imgOne="formState.thumb" :style="'button'" :btntxt="'上传封面'" />
              </a-form-item-rest>
            </div>
            <a-image v-if="formState.thumb != ''" :width="200" :src="formState.thumb" style="margin-top:10px" />
          </a-form-item>
          <a-form-item label="属性">
            <a-checkbox-group v-model:value="formState.flag">
              <a-checkbox value="1" name="flag">置顶</a-checkbox>
              <a-checkbox value="2" name="flag">推荐</a-checkbox>
              <a-checkbox value="3" name="flag">热门</a-checkbox>
            </a-checkbox-group>
          </a-form-item>
          <a-form-item label="描述" name="description">
            <a-textarea v-model:value="formState.description" placeholder="请输入描述" />
          </a-form-item>
          <a-form-item label="属性" name="content_type">
            <a-radio-group v-model:value="formState.content_type">
              <a-radio :value="item.code" v-for="(item, index) in contentModel" :key="index">{{ item.name }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-if="formState.content_type == 'document'" label="内容" name="content" :rules="[{ required: true, message: '请输入内容!' }]">
            <!--            <a-button class="margin-bottom16">导入文档</a-button>-->
            <!-- <a-textarea  placeholder="请输入内容" /> -->
            <BasicEditor v-model="formState.content"></BasicEditor>
          </a-form-item>
          <a-form-item v-else-if="formState.content_type == 'download'" label="内容" name="fileList" :rules="[{ required: true, message: '请上传附件!' }]">
            <div style="width:400px">
              <a-upload v-model:fileList="formState.fileList" class="margin-bottom16" name="iFile" :headers="headers" :multiple="true" :showUploadList="true" :action="uploadAction" @change="uploadHandleChange">
                <a-button>
                  <CloudUploadOutlined />
                  上传附件
                </a-button>
              </a-upload>
            </div>
          </a-form-item>
          <a-form-item v-else-if="formState.content_type == 'photos'" label="内容" name="imageList" :rules="[{ required: true, message: '请上传图集!' }]">
            <div class="muti-image-body">
              <div class="muti-image-item" v-for="(item, index) in formState.imageList" :key="index">
                <a-row>
                  <a-col :span="4">
                    <a-form-item-rest>
                      <CsImage v-model:imgOne="item.imgUrl" />
                    </a-form-item-rest>
                  </a-col>
                  <a-col :span="20">
                    <a-form-item-rest>
                      <a-input class="muti-image-item-title" v-model:value="item.imgTitle" placeholder="请输入标题" />
                    </a-form-item-rest>
                    <a-form-item-rest>
                      <a-input v-model:value="item.imgLink" placeholder="请输入跳转地址" />
                    </a-form-item-rest>
                  </a-col>
                </a-row>
                <CloseCircleOutlined @click="removeImageItem(item)" class="delete-image-item" />
              </div>
              <a-button type="dashed" block @click="addImageItem">
                <PlusOutlined />
                添加图片
              </a-button>
            </div>
            <!-- <mavon-editor :subfield="false" v-model:value="formState.description" /> -->
          </a-form-item>
          <a-form-item label="状态" name="status">
            <a-radio-group v-model:value="formState.status">
              <a-radio value="1">正常</a-radio>
              <a-radio value="0">待审核</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-tab-pane>
        <a-tab-pane key="2" tab="扩展信息">

          <a-form-item v-for="(item, index) in formState.extraFields" :key="index" :label="item.field_title">
            <template v-if="item.field_type == 'text'">
              <a-input v-model:value="formState.extraFields[index].value" class="medium-large-input" />
            </template>
            <template v-else-if="item.field_type == 'short_text'">
              <a-input v-model:value="formState.extraFields[index].value" class="medium-input" />
            </template>
            <template v-else-if="item.field_type == 'long_text'">
              <a-input v-model:value="formState.extraFields[index].value" />
            </template>
            <template v-else-if="item.field_type == 'number'">
              <a-input-number v-model:value="formState.extraFields[index].value" :min="0" />
            </template>
            <template v-else-if="item.field_type == 'textarea'">
              <a-textarea v-model:value="formState.extraFields[index].value" :rows="4" :placeholder="'请输入' + item.field_title" />
            </template>
          </a-form-item>

          <a-form-item label="来源">
            <a-row>
              <a-col :span="12">
                <a-form-item no-style name="source">
                  <a-input v-model:value="formState.source" class="medium-input" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="地址" name="source_url">
                  <a-input v-model:value="formState.source_url" class="medium-input" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item label="作者">
            <a-row>
              <a-col :span="12">
                <a-form-item no-style name="author">
                  <a-input v-model:value="formState.author" class="medium-input" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="编辑" name="editor">
                  <a-input v-model:value="formState.editor" class="medium-input" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item label="发布时间">
            <a-row>
              <a-col :span="12">
                <a-form-item no-style name="push_time">
                  <a-date-picker v-model:value="formState.push_time" format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item label="SEO关键词" name="keywords">
            <a-input v-model:value="formState.keywords" placeholder="请输入SEO关键词" />
          </a-form-item>
          <a-form-item label="SEO描述" name="seo_desc">
            <a-textarea v-model:value="formState.seo_desc" placeholder="请输入SEO描述" />
          </a-form-item>
          <a-form-item label="排序" name="sort">
            <a-input-number v-model:value="formState.sort" class="medium-input"></a-input-number>
          </a-form-item>
          <a-form-item label="附件" name="extraList">
            <div style="width:400px">
              <a-upload v-model:fileList="formState.extraList" class="margin-bottom16" name="iFile" :headers="headers" :multiple="true" :showUploadList="true" :action="uploadAction" @change="uploadHandleChange">
                <a-button type="dashed" block class="medium-input">
                  <PlusOutlined />
                  添加附件
                </a-button>
              </a-upload>
            </div>
          </a-form-item>
        </a-tab-pane>
      </a-tabs>
      <a-form-item :wrapper-col="{ span: 12, offset: 2 }">
        <a-button type="primary" html-type="submit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, onMounted } from "vue";
import { message, Modal } from "ant-design-vue";
import CsImage from "@/components/UpImage";
import * as Api from "@/views/document/api";
import "./style/publish.less";
import {
  CloseCircleOutlined,
  PlusOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons-vue";
import storage from "store";
// import { message } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import BasicEditor from "@/components/Editor";

export default defineComponent({
  components: {
    CloseCircleOutlined,
    PlusOutlined,
    CsImage,
    CloudUploadOutlined,
    BasicEditor,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const category_id = route.query.category_id;

    const formRef = ref();
    //表单数据
    const formState = reactive({
      category_id: category_id,
      title: "",
      thumb: "",
      description: "",
      content_type: "document",
      content: "",
      fileList: [],
      imageList: [],
      status: "1",
      flag: [],

      // 扩展信息
      source: "",
      source_url: "",
      author: "",
      editor: "",
      push_time: "",
      keywords: "",
      seo_desc: "",
      sort: 100,
      extraFields: [],
      extraList: [],
    });

    const addImageItem = () => {
      formState.imageList.push({
        imgUrl: "",
        imgTitle: "",
        imgLink: "",
      });
    };

    const removeImageItem = (item) => {
      if (formState.imageList.length > 1) {
        let index = formState.imageList.indexOf(item);

        if (index !== -1) {
          formState.imageList.splice(index, 1);
        }
        message.success("删除成功");
      } else {
        message.warning("至少保留1项！");
      }
    };

    const contentModel = ref([
      {
        name: "文档",
        code: "document",
      },
      {
        name: "下载",
        code: "download",
      },
      {
        name: "图集",
        code: "photos",
      },
    ]);

    const resetForm = () => {
      formRef.value.resetFields();
    };

    const onFinish = () => {
      formRef.value
        .validate()
        .then(() => {
          Api.articleCreate(formState).then((res) => {
            if (res.status === 200) {
              if (res.data == true) {
                Modal.confirm({
                  title: "提示",
                  content: res.message,
                  okText: "继续添加",
                  cancelText: "返回列表",
                  onOk() {
                    resetForm();
                  },
                  onCancel() {
                    router.push({
                      path: "/document/content/list",
                      query: {
                        category_id: category_id,
                      },
                    });
                  },
                });
              } else {
                Modal.success({
                  title: "提示",
                  content: res.message,
                  okText: "返回列表",
                  onOk() {
                    router.push({
                      path: "/document/content/list",
                      query: {
                        category_id: category_id,
                      },
                    });
                  },
                });
              }
            } else {
              message.error(res.message);
            }
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    //上传文件
    const uploadAction = Api.upload;

    const uploadHandleChange = (info) => {
      if (info.file.status === "done") {
        if (info.file.response.status === 200) {
          message.success(`${info.file.name} 上传成功`);
          console.log(formState.fileList);
        } else {
          message.error(info.file.response.message);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上传失败.`);
      }
    };

    const onLoadInit = () => {
      Api.categoryDetail({
        category_id: category_id,
      }).then((res) => {
        if (res.status === 200) {
          formState.extraFields = res.data.fields;
        }
      });
    };

    onMounted(() => {
      // 获取文档数据
      onLoadInit();
    });

    const headers = ref({
      "Access-Token": storage.get("ACCESS_TOKEN"),
    });

    return {
      formRef,
      formState,
      contentModel,
      onFinish,
      onFinishFailed,
      addImageItem,
      removeImageItem,
      formItemLayout: {
        labelCol: {
          span: 2,
        },
        wrapperCol: {
          span: 16,
        },
      },
      uploadAction,
      uploadHandleChange,
      headers,
    };
  },
});
</script>